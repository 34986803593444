<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 角色管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="handle-del mr10"
          @click="addmenu"
          >添加角色</el-button
        >
        <!-- <el-input v-model="query.name" placeholder="用户名" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button> -->
      </div>
      <el-table :data="tableData" border  class="table" ref="multipleTable" header-cell-class-name="table-header" @selection-change="handleSelectionChange">
        <!-- <template #default="scope">￥{{ scope.row.name }}</template> -->
        <el-table-column prop="list_order" label="排序" width="66" align="center"></el-table-column>
        <el-table-column prop="id" label="ID" width="66" align="center"></el-table-column>
        <el-table-column prop="name" label="角色名称" align="center"></el-table-column>
        <el-table-column prop="create_time" :formatter="timestampToTime" align="center" label="创建时间"></el-table-column>
        <el-table-column prop="update_time" :formatter="timestampToTimeupdate" align="center" label="更新时间"></el-table-column>
        <el-table-column label="角色描述" align="center">

          <template #default="{ row }">
            <span>{{
              row.remark == ''
                ? "-"
                : row.remark
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="88">
          <template #default="{ row }">
            <span>{{
              row.status == 0
                ? "已禁用"
                : row.status == 1
                ? "已启用"
                : ""
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280" align="center">
          <template #default="scope">
            <el-button type="text" icon="el-icon-edit-outline" :disabled="scope.row.id ==1" @click="addsmall(scope.$index, scope.row)" >权限设置</el-button>
            <el-button type="text" icon="el-icon-edit" :disabled="scope.row.id ==1" @click="handleEdit(scope.$index, scope.row)" >编辑</el-button>
            <el-button type="text" icon="el-icon-delete" :class="scope.row.id == 1 ? '':'red' " :disabled="scope.row.id ==1" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
        background layout="total, prev, pager, next" 
        :current-page="hoteldatares.page_index" 
        :page-size="hoteldatares.page_size" 
        :total="pageTotal" 
        @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 添加弹出框 -->
    <el-dialog title="添加角色" v-model="addVisible" width="40%">
      <el-form :model="addform" :rules="rules" ref="addform" label-width="100px" class="demo-ruleForm">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="addform.name"></el-input>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input v-model="addform.remark"></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="addform.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序" prop="list_order">
          <el-input v-model="addform.list_order"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="addmenuFun('addform')">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑角色" v-model="editVisible" width="40%">
      <el-form :model="editform" :rules="rules" ref="addform" label-width="100px" class="demo-ruleForm">
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="editform.name"></el-input>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input v-model="editform.remark"></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="editform.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序" prop="list_order">
          <el-input v-model="editform.list_order"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 权限设置弹窗 -->
    <el-dialog title="权限设置" v-model="jurisdictionVisible" width="40%">
      <el-tree
        :data="horizeArr"
        ref="trees"
        show-checkbox
        node-key="id"
        highlight-current
        accordion
        check-strictly
        v-model="horizedata.role_id"
        :default-expanded-keys="[]"
        :default-checked-keys="resourceCheckedKey"
         @check="currentChecked"
        :props="defaultProps">
      </el-tree>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="horizesaveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { role_getlist,role_add,delete_data,edit_rdata,authorize_infolist,add_authorize_list} from "../../api/index";
// import moment from 'moment'
export default {
  name: "basetable",
  data() {
    return {
      //添加菜单数据
      addform: {
        name: this.name,
        remark:this.remark,
        status: this.status || 1,
      },
      editform:{
        name: this.name,
        remark:this.remark,
        status: this.status || 1,
      },
      horizedata:{
        role_id:this.role_id
      },
      hoteldatares: {
        page_index: 1,
        page_size: 5,
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: "blur",
          },
        ],
        list_order: [
          {
            required: true,
            message: "请输入排序，此排序展示于角色级别",
            trigger: "blur",
          },
        ],
      },
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      multipleSelection: [],
      menu_id_arr:'',
      delList: [],
      editVisible: false,
      addVisible: false,
      jurisdictionVisible: false,
      pageTotal: 0,
      form: {},
      horizeArr: [],
      resourceCheckedKey:[],//数据回显
        defaultProps: {
          children: 'children',
          label: 'title'
        }
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData() {
      role_getlist().then((res) => {
        this.tableData = res.data.role;
        this.pageTotal = res.data.count;
      });
    },
    // 数据权限展示
    getauthorizeData(id){
      var horizeData = {id:id}
      authorize_infolist(horizeData).then((res) =>{
        this.horizeArr = res.data.menu_list
        this.resourceCheckedKey = res.data.check_id_arr
      })
    },
    //创建时间格式化
    timestampToTime(row) {
      if(row.create_time == 0){
        return "-"
      }else{
        var date = new Date(row.create_time * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = (date.getDate() +1 < 10 ? '0'+(date.getDate()+1) : date.getDate()+1) + ' ';

        var h = (date.getHours() +1 < 10 ? '0'+(date.getHours()+1) : date.getHours()+1) + ':';
        var m = (date.getMinutes() +1 < 10 ? '0'+(date.getMinutes()+1) : date.getMinutes()+1) + ':';
        var s = (date.getSeconds() +1 < 10 ? '0'+(date.getSeconds()+1) : date.getSeconds()+1) + ' ';
        return Y+M+D+h+m+s;
      }
    },
    //更新时间格式化
    timestampToTimeupdate(row) {
      if(row.update_time == 0){
        return "-"
      }else{
        var date = new Date(row.update_time * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = (date.getDate() +1 < 10 ? '0'+(date.getDate()+1) : date.getDate()+1) + ' ';

        var h = (date.getHours() +1 < 10 ? '0'+(date.getHours()+1) : date.getHours()+1) + ':';
        var m = (date.getMinutes() +1 < 10 ? '0'+(date.getMinutes()+1) : date.getMinutes()+1) + ':';
        var s = (date.getSeconds() +1 < 10 ? '0'+(date.getSeconds()+1) : date.getSeconds()+1) + ' ';
        return Y+M+D+h+m+s;
      }
    },
    // 删除操作
    handleDelete(index,row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      }).then(() => {
        console.log(row);
        var data = {
            id:row.id
        }
         delete_data(data).then((res) => {
             if(res.code == 1){
                console.log(res);
                this.$message.success("删除成功");
                this.tableData.splice(index, 1);
                this.getData();
             }else{
                this.$message.error(res.msg);
             }
            });
        })
        .catch(() => {});
    },
    // 添加菜单
    addmenu() {
      this.addVisible = true;
    },
    // 添加保存
    addmenuFun(addform) {
      console.log(this.addform);
      this.$refs[addform].validate((valid) => {
        if (valid) {
          this.addVisible = false;

          role_add(this.addform).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.getData()
            } else {
              this.$message.error(res.error);
            }
          });
        } else {
          this.addVisible = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.editform = row;
      this.editform.editId = row.id;
      this.editVisible = true;
    },
    addsmall(index, row){
      this.getauthorizeData(row.id)
      this.horizedata.role_id = row.id;
      this.jurisdictionVisible = true
    },
    // 保存编辑
    saveEdit() {
      console.log(this.editform);
      edit_rdata(this.editform).then((res) => {
        if(res.code == 1){
            this.$message.success(res.msg);
            this.editVisible = false;
            this.getData()
        }else{
            this.$message.error(res.msg);
        }
      })
    },
    currentChecked (nodeObj, SelectedObj) {
      this.menu_id_arr = SelectedObj.checkedKeys
    },
    // 角色授权保存编辑
    horizesaveEdit() {
      var horData = {
        role_id: this.horizedata.role_id,
        menu_id_arr :Object.values(this.menu_id_arr)
      }
      add_authorize_list(horData).then((res) => {
        if(res.code == 1){
            this.$message.success(res.msg);
            this.jurisdictionVisible = false;
            this.getData()
        }else{
            this.$message.error(res.msg);

        }
      })
    },
    // 取消操作
    cancel(){
      this.addVisible = false
      this.editVisible = false
      this.jurisdictionVisible = false
      this.getData()
    },
    // 分页导航
    handleCurrentChange(val) {
      this.hoteldatares.page_index = val;
      console.log(this.hoteldatares.page_index)  //点击第几页
      this.getData();
    },
  },
};
</script>

<style scoped>
.text_01 {
  font-size: 13px;
  color: red;
}
.text_01 a {
  color: royalblue;
  text-decoration: underline;
  margin-left: 3px;
  margin-right: 3px;
}
.title_01 {
  margin-bottom: 20px;
}
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
